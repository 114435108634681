var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "content-right-wrapper"
  }, [_vm._m(0), _c('div'), _c('div', {
    staticClass: "add"
  }, [_c('span', {
    staticClass: "add-btn-cls",
    on: {
      "click": _vm.add
    }
  }, [_vm._v("新增")])]), _c('div', {
    staticStyle: {
      "height": "20px"
    }
  }), _c('div', {
    staticClass: "table-div"
  }, [_c('table', {
    staticClass: "v2-table"
  }, [_vm._m(1), _vm.planList.length == 0 ? _c('tr', [_vm._m(2)]) : _vm._e(), _vm._l(_vm.planList, function (planItem, index) {
    return _c('tbody', {
      key: index
    }, [_c('tr', {
      staticClass: "head"
    }, [_c('td', {
      staticStyle: {
        "color": "#666"
      },
      attrs: {
        "colspan": "2"
      }
    }, [_vm._v(_vm._s(planItem.createTime))]), _c('td', {
      attrs: {
        "colspan": "2"
      }
    }, [_c('span', {
      staticStyle: {
        "color": "#666"
      }
    }, [_vm._v("计划单号:")]), _vm._v(_vm._s(planItem.orderNo) + " ")]), _c('td', {
      attrs: {
        "colspan": "4"
      }
    }, [planItem.remarks ? _c('div', {
      staticClass: "remark"
    }, [_vm._v(" 备注：" + _vm._s(planItem.remarks) + " ")]) : _vm._e()])]), _c('tr', [_c('td', {
      staticClass: "detail",
      attrs: {
        "colspan": "2"
      }
    }, [_c('div', {
      staticClass: "ff v2-g-flex-row"
    }, [planItem.imageList.length > 0 ? _c('div', {
      directives: [{
        name: "viewer",
        rawName: "v-viewer"
      }],
      staticClass: "t_file images v2-g-flex-row"
    }, [_vm._l(planItem.imageList, function (file, index) {
      return _c('img', {
        key: file,
        attrs: {
          "src": file
        }
      });
    }), planItem.isMoreImageList ? _c('span', [_vm._v("...")]) : _vm._e()], 2) : _vm._e()])]), _c('td', {
      staticClass: "detail",
      attrs: {
        "colspan": "2"
      }
    }, [planItem.fileList.length > 0 ? _c('div', {
      staticClass: "t_file v2-g-flex-column"
    }, [_vm._l(planItem.fileList, function (file, i) {
      return _c('span', {
        staticClass: "exls"
      }, [_c('a', {
        attrs: {
          "href": "javascript:void(0)"
        },
        on: {
          "click": function click($event) {
            return _vm.openExcel(file);
          }
        }
      }, [_vm._v("excel文档" + _vm._s(i + 1))])]);
    }), planItem.isMoreExcel ? _c('span', [_vm._v("...")]) : _vm._e()], 2) : _vm._e()]), _c('td', [_vm._v(_vm._s(planItem.statusName))]), _c('td', [_vm._v(_vm._s(planItem.handleTime))]), _c('td', [_vm._v(_vm._s(planItem.handleResult))]), _c('td', [_c('a', {
      staticStyle: {
        "margin-right": "5px"
      },
      attrs: {
        "href": "javascript:void(0)"
      },
      on: {
        "click": function click($event) {
          return _vm.detail(planItem.planId);
        }
      }
    }, [_vm._v("详情")]), planItem.status == 1 ? _c('a', {
      staticStyle: {
        "margin-right": "5px"
      },
      attrs: {
        "href": "javascript:void(0)"
      },
      on: {
        "click": function click($event) {
          return _vm.update(planItem.planId);
        }
      }
    }, [_vm._v("编辑")]) : _vm._e(), planItem.status <= 2 ? _c('a', {
      attrs: {
        "href": "javascript:void(0);"
      },
      on: {
        "click": function click($event) {
          return _vm.cancelPlan(planItem.planId);
        }
      }
    }, [_vm._v("作废")]) : _vm._e()])])]);
  })], 2)]), _vm.planList.length !== 0 ? _c('div', {
    staticClass: "pagination",
    staticStyle: {
      "width": "940px",
      "justify-content": "flex-end",
      "margin-left": "0px"
    }
  }, [_c('Pager', {
    staticClass: "is-centered",
    attrs: {
      "pageInfo": _vm.pageInfo
    },
    on: {
      "getdata": _vm.handlePageChange
    }
  })], 1) : _vm._e()]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "right-title"
  }, [_c('span', [_vm._v("报计划")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('thead', [_c('tr', [_c('th', {
    attrs: {
      "colspan": "2",
      "width": "300"
    }
  }, [_vm._v("图片")]), _c('th', {
    attrs: {
      "colspan": "2",
      "width": "300"
    }
  }, [_vm._v("文档")]), _c('th', {
    attrs: {
      "width": "150"
    }
  }, [_vm._v("状态")]), _c('th', {
    attrs: {
      "width": "150"
    }
  }, [_vm._v("处理时间")]), _c('th', {
    attrs: {
      "width": "150"
    }
  }, [_vm._v("处理结果")]), _c('th', {
    attrs: {
      "width": "150"
    }
  }, [_vm._v("操作")])]), _c('tr', {
    staticClass: "sep"
  }, [_c('td', {
    attrs: {
      "colspan": "8"
    }
  })])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('td', {
    attrs: {
      "colspan": "8"
    }
  }, [_c('div', {
    staticClass: "empty-list"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets-v2/images/pic_empty.png"),
      "alt": ""
    }
  }), _c('span', [_vm._v("暂无数据")])])]);
}];
export { render, staticRenderFns };